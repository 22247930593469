// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_mixins";

.priority-areas {
  background-size: 100% 100%;
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat;
}
.priority-areas .page-item-container {
  height: 240px;
  padding-top: .3em;
  @include breakpoint($sm) {
    margin: 0 auto;
    width: 660px;
    padding: 0;
  }
  @include breakpoint($md) {
    margin: 0 auto;
    width: 960px;
    height: 380px;
    padding: 0;
  }
}

.priority-areas .page-item-container .page-item-container-inner {
  margin: 0 1em;
  @include v-center();
  @media only screen and (max-width: 360px) {
    width: 155px;
    font-size: 10px;
  }
  @media only screen and (min-width: 360px) and (max-width: 480px) {
    width: 185px;
    font-size: 12px;
  }
  @media only screen and (min-width: 480px) and (max-width: 568px) {
    width: 245px;
    font-size: 14px;
  }
  @media only screen and (min-width: 568px) and (max-width: 678px) {
    width: 285px;
    font-size: 15px;
  }
  @media only screen and (min-width: 678px) and (max-width: 768px) {
    width: 350px;
    font-size: 15px;
  }
  @include breakpoint($sm) {
    width: 345px;
  }
  @include breakpoint($md) {
    width: 500px;
  }

  &:before {
    content: '';
    background: rgba(179, 27, 27, 1);
    position: absolute;
    width: 105%;
    height: 105%;
    top: -2%;
    left: -2%;
    z-index: -1;
  }
}

.priority-areas h2 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 25px;
  text-shadow: 0 0 1px #363636;

  @include breakpoint($xs) {
    margin-top: 0;
  }
  @include breakpoint($sm-only) {
    margin-top: 0;
  }
  @include breakpoint($sm) {
    font-size: 2.6rem;
  }
  @include breakpoint($md) {
    font-size: 4rem;
  }
}

.priority-areas p {
  color: #fff;
  text-shadow: 0 0 1px #363636;
  @include breakpoint($sm) {
    font-size: 1.7rem;
  }
  @include breakpoint($md) {
    font-size: 2.2rem;
  }
}
