/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
.priority-areas {
  background-size: 100% 100%;
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat;
}

.priority-areas .page-item-container {
  height: 240px;
  padding-top: .3em;
}

@media screen and (min-width: 768px) {
  .priority-areas .page-item-container {
    margin: 0 auto;
    width: 660px;
    padding: 0;
  }
}

@media screen and (min-width: 992px) {
  .priority-areas .page-item-container {
    margin: 0 auto;
    width: 960px;
    height: 380px;
    padding: 0;
  }
}

.priority-areas .page-item-container .page-item-container-inner {
  margin: 0 1em;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
  -ms-transform: translateY(-50%);
  /* IE 9 */
  transform: translateY(-50%);
  /* IE 10, Fx 16+, Op 12.1+ */
}

@media only screen and (max-width: 360px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 155px;
    font-size: 10px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 185px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 568px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 245px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 678px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 285px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 678px) and (max-width: 768px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 350px;
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 345px;
  }
}

@media screen and (min-width: 992px) {
  .priority-areas .page-item-container .page-item-container-inner {
    width: 500px;
  }
}

.priority-areas .page-item-container .page-item-container-inner:before {
  content: '';
  background: #b31b1b;
  position: absolute;
  width: 105%;
  height: 105%;
  top: -2%;
  left: -2%;
  z-index: -1;
}

.priority-areas h2 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 25px;
  text-shadow: 0 0 1px #363636;
}

@media screen and (max-width: 767px) {
  .priority-areas h2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .priority-areas h2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .priority-areas h2 {
    font-size: 2.6rem;
  }
}

@media screen and (min-width: 992px) {
  .priority-areas h2 {
    font-size: 4rem;
  }
}

.priority-areas p {
  color: #fff;
  text-shadow: 0 0 1px #363636;
}

@media screen and (min-width: 768px) {
  .priority-areas p {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 992px) {
  .priority-areas p {
    font-size: 2.2rem;
  }
}
